import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import SideBarLayout from '../../components/AuthHeader/sideBarLayout';
import { jobPost } from '../../apis/Apis';
import { getDataFromLocalStorage } from '../../utils/localStorage';


const EmployerScreen = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('');
    const [redirect, setRedirect] = useState(false);

    const [formData, setFormData] = useState({
        title: '', 
        salary: '', 
        job_type: '', 
        experience: '', 
        industry: '', 
        deadline: '', 
        country: '', 
        city: '', 
        address: '', 
        map: '', 
        logo: 'logo'
    });
    useEffect(() => {
        if (redirect) {
            const timer = setTimeout(() => {
                navigate('/manage-jobs');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [redirect]);

    const handleChange = (e) => {
        const { name, value } = e.target;
            setFormData({
                ...formData,
                [name]: value
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('')
        setSuccess('')
        const data = new URLSearchParams();
        const { id, ...rest } = formData;
        for (const key in rest) {
            data.append(key, formData[key]);
        }
        const userData = await getDataFromLocalStorage('userData');
        const userDataParse = JSON.parse(userData);
        console.log("userDataParse: ", userDataParse);
        data.append('employerId', userDataParse.id);

        const header = `Bearer ${userDataParse.token}`;

        const response = await jobPost(data, header)
        console.log("response 555 : ", response)
        if (!response.data.success) {
            setError(response.data.message);
            return;
        }
        setSuccess(response.data.message);
        setRedirect(true)
    };

    return (
        <SideBarLayout>
            <main id='main'>
                <h1>Post a New Job!</h1>
                {success && <div className="alert alert-success mt-3">{success}</div>}
                {error && <div className="alert alert-danger mt-3">{error}</div>}
                <form id="jobForm" onSubmit={handleSubmit} >

                    <div className="form-group">
                        <label for="title">Job Title</label>
                        <input 
                        type="text" 
                        id="title" 
                        name="title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                        />
                    </div>
                    <div className="form-group">
                        <label for="salary">Offered Salary</label>
                        <input 
                        type="text" 
                        id="salary" 
                        name="salary"
                        value={formData.salary}
                        onChange={handleChange}
                        required />
                    </div>
                    <div className="form-group">
                        <label for="job-type">Job Type</label>
                        <input 
                        type="text" 
                        id="job-type" 
                        name="job_type" 
                        value={formData.job_type}
                        onChange={handleChange}
                        required />
                    </div>
                    <div className="form-group">
                        <label for="experience">Experience</label>
                        <input 
                        type="text" 
                        id="experience" 
                        name="experience"
                        value={formData.experience}
                        onChange={handleChange} 
                        required />
                    </div>
                    <div className="form-group">
                        <label for="industry">Industry</label>
                        <input 
                        type="text" 
                        id="industry" 
                        name="industry" 
                        value={formData.industry}
                        onChange={handleChange}
                        required />
                    </div>
                    <div className="form-group">
                        <label for="deadline">Application Deadline Date</label>
                        <input 
                        type="date" 
                        id="deadline" 
                        name="deadline" 
                        value={formData.deadline}
                        onChange={handleChange}
                        required />
                    </div>
                    <div className="form-group">
                        <label for="country">Country</label>
                        <input 
                        type="text" 
                        id="country" 
                        name="country" 
                        value={formData.country}
                        onChange={handleChange}
                        required />
                    </div>
                    <div className="form-group">
                        <label for="city">City</label>
                        <input 
                        type="text" 
                        id="city" 
                        name="city" 
                        value={formData.city}
                        onChange={handleChange}
                        required />
                    </div>
                    <div className="form-group">
                        <label for="address">Complete Address</label>
                        <input 
                        type="text" 
                        id="address" 
                        name="address" 
                        value={formData.address}
                        onChange={handleChange}
                        required />
                    </div>
                    <div className="form-group">
                        <label for="map">Nearby LandMark</label>
                        <input 
                        type="text" 
                        id="map" 
                        name="map" 
                        value={formData.map}
                        onChange={handleChange}
                        required />
                    </div>
                    {/* <div className="form-group">
                        <label for="logo">Company Logo</label>
                        <input 
                        type="file" 
                        id="logo" 
                        name="logo" 
                        onChange={handleChange}
                        className="form-control" />
                    </div> */}
                    <Button type="submit">Submit</Button>
                </form>


            </main>
        </SideBarLayout>
    )
}

export default EmployerScreen