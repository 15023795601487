import React, { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import SideBarLayout from '../../components/AuthHeader/sideBarLayout';
import { jobGetByUserId, jobDelete } from '../../apis/Apis';
import { getDataFromLocalStorage } from '../../utils/localStorage';
import Image from 'react-bootstrap/Image';
import { BASE_URL } from '../../apis/Helper';
import { useAuth } from '../../context/auth';


const ManageJobsScreen = () => {
    const { isAuthenticatedUser } = useAuth();
    const [loading, setLoading] = useState(true)
    const [formData, setFormData] = useState({});
    const [companyLogo, setCompanyLogo] = useState(null)
    const [token, setToken]= useState(null)
    console.log("formData: ", formData)
    useEffect(() => {
        const fetchData = async () => {
            const getCompanyProfile = await getDataFromLocalStorage('companyProfile');
            const companyProfileData = JSON.parse(getCompanyProfile);
            setCompanyLogo(companyProfileData?.logo)
            const getData = await getDataFromLocalStorage("userData");
            const getUserData = JSON.parse(getData);
            setToken(getUserData.token)
            //console.log("getUserData: ", getUserData);
            const header = `Bearer ${getUserData.token}`;
            const response = await jobGetByUserId(getUserData.id, header);
            const data = response.data;
            //console.log("response: ", response.data.data);
            if (data && response.data.success) {
                setFormData(response.data.data);
            }
            setLoading(false)
        };

        fetchData();
    }, []);

    const deleteJob = async (id) =>{
        const header = `Bearer ${token}`;
        const response  = await jobDelete(id, header);
        console.log("id", response)
    }

    return (
        <SideBarLayout>
            <main id='main'>
                <h1>Manage Jobs</h1>
                <div id="employerJobsList" className="row">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Logo</th>
                                <th>Job Title</th>
                                <th>Salary</th>
                                <th>Job Type</th>
                                <th>Experience</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                Array.isArray(formData) && formData.length > 0 ? (
                                    formData.map((data) => {
                                        console.log("data: ", data)
                                        return(
                                        <tr key={data.id}>
                                            <td>{data.id}</td>
                                            <td><Image style={{width:'100px', height:'100px', objectFit:'cover'}} src={`${BASE_URL}/useruploads/${companyLogo}`} roundedCircle/></td>
                                            <td>{data.title}</td>
                                            <td>{data.salary}</td>
                                            <td>{data.job_type}</td>
                                            <td>{data.experience}</td>
                                            <td><div className="" onClick={() => deleteJob(data.id)}><i className="fa-solid fa-delete-left"></i></div></td>
                                        </tr>
                                    )})
                                ) : (
                                    <tr>
                                        <td colSpan={6}>
                                            {(!loading && (!formData || Object.keys(formData).length === 0)) ? (
                                                <Spinner animation="border" />
                                            ) : 'No Data Found'
                                            }
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </Table>
                </div>
            </main>
        </SideBarLayout>
    )
}

export default ManageJobsScreen