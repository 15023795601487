import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import SideBarLayout from '../../components/AuthHeader/sideBarLayout';
import { companyPost, companyUpdateById } from '../../apis/Apis';
import { getDataFromLocalStorage, removeDataFromLocalStorage, saveDataToLocalStorage } from '../../utils/localStorage';
import { useAuth } from '../../context/auth';

const CompanyProfileScreen = () => {
    const { isAuthenticatedUser } = useAuth();
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('');
    const [formData, setFormData] = useState({
        id: null,
        company_name: '',
        email: '',
        description: '',
        phone: '',
        website: '',
        logo: null
    });
    console.log("formData: ", formData);
    useEffect(() => {
        const fetchData = async () => {
            //await removeDataFromLocalStorage(`companyProfile-${isAuthenticatedUser.userId}`);
            const getCompanyProfile = await getDataFromLocalStorage('companyProfile');
            const companyProfileData = JSON.parse(getCompanyProfile);
            if (companyProfileData) {
                setFormData({
                    id: companyProfileData.id || formData.id,
                    company_name: companyProfileData.company_name || formData.company_name,
                    email: companyProfileData.email || formData.email,
                    description: companyProfileData.description || formData.description,
                    phone: companyProfileData.phone || formData.phone,
                    website: companyProfileData.website || formData.website,
                    logo: companyProfileData.logo || formData.logo
                });
            }
        };

        fetchData();
    }, [success]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'logo') {
            setFormData({
                ...formData,
                logo: files[0]
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('')
        setSuccess('')
        if (formData.phone) {
            const sanitizedValue = formData.phone.replace(/\D/g, "");
            if (sanitizedValue.length !== 10) {
                setError('Wrong mobile number')
                return;
            }
        }
        const data = new FormData();
        const { id, ...rest } = formData;
        for (const key in rest) {
            data.append(key, formData[key]);
        }
        const userData = await getDataFromLocalStorage('userData');
        const userDataParse = JSON.parse(userData);
        console.log("userDataParse: ", userDataParse);
        data.append('user_id', userDataParse.id);

        const header = `Bearer ${userDataParse.token}`;
        
        let response = null;
        
        if (!id) {
            response = await companyPost(data, header)
        } else {
            response = await companyUpdateById(id, data, header)
        }
        //console.log("response 222 : ", response)
        const responseData = response.data
        if (!responseData && !responseData.success) {
            setError(responseData.message);
            return;
        }
        setFormData((prevFormData) => ({
            id: responseData?.id,
            logo: response?.filename,
            ...prevFormData
        }));
        await saveDataToLocalStorage('companyProfile', JSON.stringify({
            id: responseData.id,
            company_name: formData.company_name,
            email: formData.email,
            description: formData.description,
            phone: formData.phone,
            website: formData.website,
            logo: responseData?.filename
        }));
        setSuccess(response.data.message);
    }; 

    return (
        <SideBarLayout>
            <main id='main'>
                <h1>Company Profile!</h1>
                {success && <div className="alert alert-success mt-3">{success}</div>}
                {error && <div className="alert alert-danger mt-3">{error}</div>}
                <form id="companyProfileForm" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="form-group">
                        <label for="companyName">Company Name</label>
                        <input
                            type="text"
                            id="company_name"
                            name="company_name"
                            value={formData.company_name}
                            onChange={handleChange}
                            required />
                    </div>

                    <div className="form-group">
                        <label for="email">Email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label for="description">Description</label>
                        <textarea
                            id="description"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <div className="form-group">
                        <label for="phone">Phone Number</label>
                        <input
                            type="text"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required />
                    </div>
                    <div className="form-group">
                        <label for="website">Website</label>
                        <input
                            type="text"
                            id="website"
                            name="website"
                            value={formData.website}
                            onChange={handleChange}
                            required />
                    </div>
                    <div className="form-group">
                        <label for="logo">Upload Logo</label>
                        <input
                            type="file"
                            id="logo"
                            name="logo"
                            onChange={handleChange}
                             />
                    </div>
                    <Button type="submit">Save Changes</Button>
                </form>
                {/* <div className="profile-display" id="profileDisplay">
                    <h3>Your Profile</h3>
                    <p><strong>Company Name:</strong> <span id="displayCompanyName"></span></p>
                    <p><strong>Email:</strong> <span id="displayEmail"></span></p>
                    <p><strong>Description:</strong> <span id="displayDescription"></span></p>
                    <p><strong>Phone Number:</strong> <span id="displayPhone"></span></p>
                    <p><strong>Website:</strong> <span id="displayWebsite"></span></p>
                </div> */}
            </main>
        </SideBarLayout>
    )
}

export default CompanyProfileScreen