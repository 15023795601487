import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useAuth } from '../../context/auth';
//import { useNavigate } from 'react-router-dom';

//import './style.css';

const LoginScreen = () => {
    const { login, isAuthenticatedUser } = useAuth();
    //const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ phone: '', password: '' });
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('');
    const [redirect, setRedirect] = useState(false);

    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    //console.log("isAuthenticatedUser:", isAuthenticatedUser);
    useEffect(() => {
        if (redirect) {
            const timer = setTimeout(() => {
                window.location.href = isAuthenticatedUser?.role === 'employer' ? '/company-profile' : '/edit-profile';
                //navigate(isAuthenticatedUser?.role === 'employer' ? '/company-profile': '/freelancer-profile', { replace: true });
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [redirect, isAuthenticatedUser?.role]);

    const handleChange = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('')
        setSuccess('')
        
        if (credentials.phone) {
            const sanitizedValue = credentials.phone.replace(/\D/g, "");
            if (sanitizedValue.length !== 10) {
                setError('Wrong mobile number')
                return;
            }
        }
        const response = await login(credentials);
        console.log("response ghghgh : ", response)
        if (!response.success) {
            setError(response.message);
            return;
        }
        setSuccess(response.message);
        setRedirect(true);
    };

    return (
        <>
            <div className="login-container">
                <div className="form-container">
                    <form id="loginForm" onSubmit={handleSubmit}>
                        <h2 className="form-header">Login</h2>

                        {success && <div className="alert alert-success mt-3">{success}</div>}
                        {error && <div className="alert alert-danger mt-3">{error}</div>}

                        <div className="form-group">
                            <label className='text-start' htmlFor="phone">Phone Number</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={credentials.phone}
                                onChange={handleChange}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <input
                                type={passwordVisible ? 'text' : 'password'}
                                name="password"
                                id="password"
                                placeholder="Password"
                                value={credentials.password}
                                onChange={handleChange}
                                required
                            />
                            <span className="eye-icon" onClick={togglePasswordVisibility}>
                                <i className="far fa-eye"></i>
                            </span>
                        </div>

                        <div className="form-group form-forgot">
                            <a href="/forgot-password">Forgot password?</a>
                        </div>

                        <div className="form-group">
                            <Button type="submit" className="form-button">Login</Button>
                        </div>

                        <div className="form-group form-link">
                            <p>Don't have an account? <a href="/signup">Signup</a></p>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default LoginScreen;
