import React, { useEffect, useState } from 'react';
import { BASE_URL } from '../../apis/Helper';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/esm/Image';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';

const AppliedApplicants = ({ formData, applyForJob }) => {
    const [defaultActiveKey, setDefaultActiveKey] = useState(null)
    useEffect(() => {
        setDefaultActiveKey(formData[0]?.job_id)
    }, [formData])
    //console.log("defaultActiveKey ", defaultActiveKey);
    return (
        <>
            {defaultActiveKey && (
                <Tab.Container id="left-tabs-example" defaultActiveKey={defaultActiveKey}>
                    <Row>
                        <Col sm={3}>
                            <Nav variant="pills" className="flex-column">
                                {formData && formData.length > 0 && formData.map(job => (
                                    <Nav.Item>
                                        <Nav.Link eventKey={job.job_id}>{job.title}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col sm={9}>
                            <Tab.Content>
                                {formData && formData.length > 0 && formData.map(job => (
                                    <Tab.Pane eventKey={job.job_id}>
                                        <div className="row g-4">
                                            {job.applicants.length > 0 ? (
                                                job.applicants.map((applicant, key) => (
                                                    <div className='col-md-6' key={applicant.applicant_id} id={key}>
                                                        <div key={job.id} className='job-item p-4 mb-4 md-px-5'>
                                                            {/* <Image className="flex-shrink-0 img-fluid border rounded" src={`${BASE_URL}/useruploads/${job.logo}`} alt="Company Logo" style={{ width: '80px', height: '80px', objectFit: 'cover' }} /> */}
                                                            <div className="text-start ps-4 jpx-0">
                                                                <h5 className="mb-3 text-capitalize">{applicant.name}</h5>
                                                                <p className="text-truncate me-3"><b>Skills: </b> {applicant.skills}</p>
                                                                <p className="text-truncate me-3"><b>Experience: </b> {applicant.experience}</p>
                                                                <p className="text-truncate me-3"><b>Education: </b> {applicant.education}</p>
                                                                <p className="text-truncate me-0"><b>Mobile No.: </b>{applicant.mobile_number}</p>
                                                                <p className="text-truncate me-0"><b>Deadline:</b> {new Date(job.deadline).toLocaleDateString()}</p>
                                                            </div>
                                                            <div className="d-flex mb-3 ps-4 jpx-0">
                                                                {applicant.job_applied_status === 'applied' ?
                                                                    (<>
                                                                        <Button variant="success" className='me-3 jme-0' onClick={() => applyForJob({ id: job.job_id, applicant: applicant.applicant_id, status: 'accepted', index: key })}>
                                                                            Accepted
                                                                        </Button>
                                                                        <Button className="btn btn-primary" onClick={() => applyForJob({ id: job.job_id, applicant: applicant.applicant_id, status: 'rejected', index: key })}>
                                                                            Rejected
                                                                        </Button>
                                                                    </>
                                                                    ) : (
                                                                        <Button className='text-capitalize' variant={applicant.job_applied_status === 'accepted' ? "outline-success" : "outline-danger"}>
                                                                            {applicant.job_applied_status}
                                                                        </Button>
                                                                    )}

                                                            </div>
                                                        </div>
                                                    </div>

                                                ))
                                            ) : (
                                                <div>No applicants for this job</div>
                                            )}
                                        </div>
                                    </Tab.Pane>
                                ))}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            )}
        </>
    );
};

export default AppliedApplicants;