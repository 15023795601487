import React, { useRef, useState } from "react";
import Slider from "react-slick";

function Slides(props) {
  const[currentSlide, setCurrentSlide] = useState(0)
  console.log("currentSlide: ", currentSlide)
  const sliderRef = useRef(null)
  const settings = {
    //className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 5000,
    pauseOnHover: true,
    afterChange: (current) => setCurrentSlide(current),
    beforeChange: (current, next) => setCurrentSlide(next),
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            centerPadding: "150px",
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
  };
  return (
    <div className="slider-container">
      <Slider ref={sliderRef} {...settings}>
        {props?.slides?.map( (slide, index) => {
          let center = props?.slides?.length;
          if(currentSlide > 0 ){
            center = currentSlide
          }
            return(
                <img current={currentSlide} dataKey={index} className={`slide ${currentSlide === index || (center - 1 === index ) ? 'center' : ''}`} key={index} src={slide.image} alt=""/>
            )
        })}
      </Slider>
    </div>
  );
}

export default Slides;
