import React, { useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
import GoogleTranslate from '../GoogleTranslate';

const Header = () => {
  const location = useLocation();
  const loadCSS = (href) => {
    const link = document.createElement('link');
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = href;
    document.head.appendChild(link);
  }

  const unloadCss = (href) => {
    const links = document.querySelectorAll(`link[href="${href}"]`);
    links.forEach(link => link.parentNode.removeChild(link));
  }

  useEffect(() => {
    const bootsTrapCss = `${process.env.PUBLIC_URL}/css/bootstrap.min.css`;
    const styleCss = `${process.env.PUBLIC_URL}/css/style.css`;
    const styleSlick = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css';
    const styleSlickTheme = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css';
    const style = `${process.env.PUBLIC_URL}/css/styles.css`;

    unloadCss(style);

    loadCSS(bootsTrapCss)
    loadCSS(styleCss)
    loadCSS(styleSlick)
    loadCSS(styleSlickTheme)

    return () => {
      unloadCss(bootsTrapCss)
      unloadCss(styleCss)
      unloadCss(styleSlick)
      unloadCss(styleSlickTheme)
    }
  }, [location]);
  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div class="container-fluid">
          <div className="logo">
            <Image src="./img/NewLogoBright.png" alt="Unnati Logo" />
            <h1>Unnati</h1>
          </div>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <Nav className="navbar-links navbar-nav m-auto" as="ul">
              <Nav.Item as="li"><Nav.Link as={NavLink} to="/" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Home</Nav.Link></Nav.Item>
              <Nav.Item as="li"><Nav.Link as={NavLink} to="/about" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >About Us</Nav.Link></Nav.Item>
              <Nav.Item as="li"><Nav.Link as={NavLink} to="/contact" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Contact</Nav.Link></Nav.Item>
              {/* <Nav.Item as="li"><Nav.Link as={NavLink} to="/timeline" className={({ isActive }) => (isActive ? 'nav-item nav-link active' : '')} >Blog</Nav.Link></Nav.Item> */}
            </Nav>
            <Nav className="navbar-links d-flex">
              <Nav.Item><Nav.Link className="get-in-touch" href="/login">Log In</Nav.Link></Nav.Item>
              <GoogleTranslate path={location.pathname} />
            </Nav>
          </div>
        </div>
      </nav>
    </header>

  )
}

export default Header