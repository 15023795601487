export const teamMember = [
    {
        id:1,
        image: './img/Aashi.jpeg',
        name: 'Aashi Goyal',
        profession: 'Founder'
    },
    {
        id:3,
        image: './img/HERSHEL.jpeg',
        name: 'Hershel Goyal',
        profession: 'Co-founder'
    },
    {
        id:2,
        image: './img/Ryna.jpeg',
        name: 'Ryna Gattani',
        profession: 'Design Head'
    },
    {
        id:4,
        image: './img/bhavya.jpeg',
        name: 'Bhavya Jain',
        profession: 'Outreach Head'
    },
    {
        id:5,
        image: './img/Arya.jpeg',
        name: 'Arya Rathi',
        profession: 'Tech Head'
    },
    {
        id:5,
        image: './img/dhawnil.jpeg',
        name: 'Dhwanil Gilara',
        profession: 'Logistics Head'
    },
    
];