import React, { useEffect, useState } from 'react'

const Footer = () => {
    const [cssLoaded, setCssLoaded] = useState(false);

    useEffect(() => {
        import('./style.css').then(() => {
            setCssLoaded(true); // Set state when CSS is loaded
        });
    }, []);
    if (!cssLoaded) return null;
    return (
        <div className="footer">
            <div className="container-fluid pt-5 mt-5">

                <div className="footer-links">
                    <a href="#">About Unnati</a>
                    <a href="#">Our Mission</a>
                    <a href="#">Job Listings</a>
                    {/* <a href="#">Community Stories</a>
                    <a href="#">Get Involved</a> */}
                </div>
                {/* <div className="footer-social">
                    <a href="#"><i className="fab fa-twitter"></i></a>
                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                    <a href="#"><i className="fab fa-instagram"></i></a>
                    <a href="#"><i className="fab fa-linkedin-in"></i></a>
                </div> */}
                <p className="copyright">&copy; 2024 Unnati. Empowering Communities Together.</p>
            </div>
            <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="fa fa-solid fa-arrow-up"></i></a>
        </div>
    )
}

export default Footer