import React, { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import SideBarLayout from '../../components/AuthHeader/sideBarLayout';
import { FreelancerPost, freelancerUpdateById } from '../../apis/Apis';
import { getDataFromLocalStorage, removeDataFromLocalStorage, saveDataToLocalStorage } from '../../utils/localStorage';
import { BASE_URL } from '../../apis/Helper';

const FreelancerProfileUpdateScreen = () => {
    const [error, setError] = useState('')
    const [success, setSuccess] = useState('');
    const [formData, setFormData] = useState({
        id: null,
        name: '',
        skills: '',
        age: '',
        mobile_number: '',
        education: 'High School',
        current_job: '',
        aadhar_card: null
    });
    //console.log("formData 333: ", window.URL.createObjectURL(formData.aadhar_card));
    useEffect(() => {
        //removeDataFromLocalStorage(`FreelancerProfile-${isAuthenticatedUser.userId}`)
        const fetchData = async () => {
            const getFreelancerProfile = await getDataFromLocalStorage('FreelancerProfile');
            const freelancerProfile = JSON.parse(getFreelancerProfile);
            if (freelancerProfile) {
                setFormData({
                    id: freelancerProfile.id,
                    name: freelancerProfile.name,
                    skills: freelancerProfile.skills,
                    age: freelancerProfile.age,
                    mobile_number: freelancerProfile.mobile_number,
                    education: freelancerProfile.education,
                    current_job: freelancerProfile.current_job,
                    aadhar_card: freelancerProfile.aadhar_card
                });
            }
        };

        fetchData();
    }, [success]);

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'aadhar_card') {
            setFormData({
                ...formData,
                aadhar_card: files[0]
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('')
        setSuccess('')
        if (formData.mobile_number) {
            const sanitizedValue = formData.mobile_number.replace(/\D/g, "");
            if (sanitizedValue.length !== 10) {
                setError('Wrong mobile number')
                return;
            }
        }
        const data = new FormData();
        const { id, ...rest } = formData;
        for (const key in rest) {
            data.append(key, formData[key]);
        }
        const userData = await getDataFromLocalStorage('userData');
        const userDataParse = JSON.parse(userData);
        console.log("userDataParse: ", userDataParse);
        data.append('user_id', userDataParse.id);

        const header = `Bearer ${userDataParse.token}`;
        let response = null;
        if (!id) {
            response = await FreelancerPost(data, header)
        } else {
            response = await freelancerUpdateById(id, data, header)
        }
        const responseData = response.data
        console.log("response response: ", response);
        if (!responseData && !responseData.success) {
            setError(responseData.message);
            return;
        }
        setFormData((prevFormData) => ({
            id: responseData?.id,
            aadhar_card: responseData?.filename,
            ...prevFormData
        }));
        await saveDataToLocalStorage('FreelancerProfile', JSON.stringify({
            id: responseData?.id,
            aadhar_card: responseData?.filename,
            name: formData.name,
            skills: formData.skills,
            age: formData.age,
            mobile_number: formData.mobile_number,
            education: formData.education,
            current_job: formData.current_job,
        }));
        setSuccess(responseData.message);
    };

    return (
        <SideBarLayout>
            <main id='main'>
                <h1>Profile</h1>
                {success && <div className="alert alert-success mt-3">{success}</div>}
                {error && <div className="alert alert-danger mt-3">{error}</div>}
                <form id="companyProfileForm" onSubmit={handleSubmit} encType="multipart/form-data">
                    <div className="d-flex">
                        <div className='image-container'>
                            <label htmlFor="aadhar_card">Government ID</label>
                            <div className="form-group">
                                <div className='id-upload'>
                                    <div className='identity'>
                                        {formData?.aadhar_card && (
                                            <img src={ typeof formData.aadhar_card === 'object' && formData.aadhar_card !== null
 ? window.URL.createObjectURL(formData.aadhar_card) : `${BASE_URL}/useruploads/${formData.aadhar_card}`} alt='' />
                                        )}
                                        <img className='edit-image' src='/img/edit-image.png' alt='' />
                                    </div>
                                    <input
                                        type="file"
                                        id="aadhar_card"
                                        name="aadhar_card"
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='input-container'>
                            <div className='d-flex'>

                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input
                                        type="text"
                                        id="name"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="companyName">Skills</label>
                                    <input
                                        type="text"
                                        id="skills"
                                        name="skills"
                                        value={formData.skills}
                                        onChange={handleChange}
                                        required />
                                </div>
                            </div>
                            <div className='d-flex'>
                                <div className="form-group">
                                    <label htmlFor="email">Age</label>
                                    <input
                                        type="number"
                                        id="age"
                                        name="age"
                                        value={formData.age}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="mobile_number">Mobile Number</label>
                                    <input
                                        type="number"
                                        id="mobile_number"
                                        name="mobile_number"
                                        value={formData.mobile_number}
                                        onChange={handleChange}
                                        required
                                    />
                                </div>
                            </div>
                            {/* <div className="form-group">
                                
                                <textarea
                                    id="mobile_number"
                                    name="mobile_number"
                                    value={formData.mobile_number}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div> */}
                            <div className="form-group">
                                <label htmlFor="education">Education</label>
                                <select
                                    className="form-control"
                                    id="education"
                                    name="education"
                                    value={formData.education}
                                    onChange={handleChange}
                                >
                                    <option value="High School">High School</option>
                                    <option value="Bachelors">Bachelors</option>
                                    <option value="Masters">Masters</option>
                                    <option value="PhD">PhD</option>
                                    <option value="None">None</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <label htmlFor="current_job">Current Job</label>
                                <input
                                    type="text"
                                    id="current_job"
                                    name="current_job"
                                    value={formData.current_job}
                                    onChange={handleChange}
                                    required />
                            </div>
                            <Button className='profile-submit' type="submit">Save Changes</Button>
                        </div>
                    </div>
                </form>
            </main>
        </SideBarLayout>
    )
}

export default FreelancerProfileUpdateScreen